<template>
  <div class="layout">
    <el-container>
      <el-aside :width="collapse ? '100px' : '215px'">
        <div class="top">
          <div class="img">
            <img
              src="http://store-tiktok.oss-cn-beijing.aliyuncs.com/mini/images/1680140741992149.png"
              alt=""
            />
            <!-- <img src="" alt="" :class="collapse ? 'hide' : ''" /> -->
          </div>
          <h2>二当家</h2>
          <!-- <i
            style="font-size: 20px"
            :class="collapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"
            @click="handleCollapse"
          ></i> -->
        </div>
        <el-menu
          :collapse="collapse"
          unique-opened
          router
          :default-active="defactive"
          class="el-menu-vertical-demo"
          text-color="#fff"
          active-text-color="#FF626D"
        >
          <!-- <template v-for="(v, i) in navList">

            <el-menu-item
              v-if="v.children && v.children.length == 1 && !v.children.path"
              :index="v.path"
              :key="i"
            >
              <i class="iconfont" :class="v.meta.icon"></i>
              <span slot="title">{{ v.meta.title }}</span>
            </el-menu-item>

            <el-submenu
              v-else-if="v.children && v.children.length"
              :index="v.path"
              :key="i"
            >
              <template slot="title">
                <i class="iconfont" :class="v.meta.icon"></i>
                <span slot="title">{{ v.meta.title }}</span>
              </template>
              <el-menu-item
                v-show="v.meta.title.includes('详情') == false"
                v-for="v in v.children"
                :key="v.path"
                :index="v.path"
              >
                {{ v.meta.title }}
              </el-menu-item>
            </el-submenu>
          </template> -->

          <el-menu-item class="home" index="/home">
            <i class="el-icon-s-home"></i>
            <span slot="title">首页</span>
          </el-menu-item>

          <el-submenu index="/master">
            <template slot="title">
              <img class="img" src="../../static/img/master.png" alt="" />
              <span>达人管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/master/list">达人列表</el-menu-item>
              <el-menu-item index="/master/list/info">达人信息</el-menu-item>
              <el-menu-item index="/master/verify">达人审核</el-menu-item>
              <el-menu-item index="/master/tiktok">平台信息</el-menu-item>
              <el-menu-item index="/master/changeMoney">余额变更</el-menu-item>
              <el-menu-item index="/master/record">操作记录</el-menu-item>
              <el-menu-item index="/master/addUser">添加用户</el-menu-item>
              <el-menu-item index="/master/userRecord">用户操作记录</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <!-- 知识库 -->
          <!-- <el-submenu index="/knowledgebase">
            <template slot="title">
              <img class="img" src="../../static/img/master.png" alt="" />
              <span>知识库</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/knowledgebase/list"
                >知识库列表</el-menu-item
              >
              <el-menu-item index="/knowledgebase/userList"
                >知识库搜索</el-menu-item
              >

              <!-- <el-menu-item index="/knowledgebase/aigc">AIGC</el-menu-item> -->
          <!-- </el-menu-item-group>
          </el-submenu> -->
          <el-submenu index="/provider">
            <template slot="title">
              <img class="img" src="../../static/img/provider.png" alt="" />
              <span>服务商管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/provider/list">服务商列表</el-menu-item>
              <!-- <el-menu-item index="/provider/info">服务商信息</el-menu-item> -->
              <!-- <el-menu-item index="/provider/list/info"
                >服务商信息</el-menu-item
              > -->

              <el-menu-item index="/provider/verify">服务商审核</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="/task">
            <template slot="title">
              <img class="img" src="../../static/img/task.png" alt="" />
              <span>任务管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/task/orders">全部订单</el-menu-item>
              <el-menu-item index="/task/cancelOrder">订单取消</el-menu-item>
              <el-menu-item index="/task/cloud">云探任务</el-menu-item>
              <el-menu-item index="/task/store">实探任务</el-menu-item>
              <el-menu-item index="/task/offline">实探线下</el-menu-item>
              <el-menu-item index="/task/qrcode">扫码直发</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="/operation">
            <template slot="title">
              <img class="img" src="../../static/img/operation.png" alt="" />
              <span>运营管理</span>
            </template>
            <el-menu-item-group>
              <!-- <el-menu-item index="/operation/qrCodeManagement">
                二维码管理
              </el-menu-item> -->
              <el-menu-item index="/operation/top">任务排序</el-menu-item>

              <el-menu-item index="/operation/banner">banner配置</el-menu-item>
              <el-menu-item index="/operation/socialCommunity"
                >社群配置</el-menu-item
              >
              <el-menu-item index="/operation/inviteFriend"
                >邀请好友</el-menu-item
              >
              <!-- <el-menu-item index="/operation/adPopupup">页面弹框</el-menu-item> -->
              <!-- <el-menu-item index="/operation/popupRoute"
                >弹框路由</el-menu-item
              > -->
              <!-- <el-menu-item index="/operation/hideTask">隐藏任务</el-menu-item> -->
              <el-menu-item index="/operation/infoClassify">
                资讯分类
              </el-menu-item>
              <el-menu-item index="/operation/infoManage">
                资讯管理
              </el-menu-item>
              <el-menu-item index="/operation/exportRecord">
                导出记录
              </el-menu-item>
              <el-menu-item index="/operation/noticeManage">
                公告管理
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="/config">
            <template slot="title">
              <i class="el-icon-set-up"></i>

              <span>配置管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/config/distributor">
                分销商配置
              </el-menu-item>
              <el-menu-item v-if="user.name == 'admin'" index="/config/money">
                任务金配置
              </el-menu-item>
              <el-menu-item index="/config/autoCancel"> 任务配置 </el-menu-item>
              <el-menu-item index="/config/customer"> 客服配置 </el-menu-item>
              <el-menu-item
                index="/config/limitWithdrawal"
                v-if="user.name == 'admin'"
              >
                提现配置
              </el-menu-item>
              <!-- <el-menu-item index="/config/replayRmark">
                达人审核配置
              </el-menu-item> -->
              <el-menu-item index="/config/link"> 链接设置 </el-menu-item>
              <el-menu-item index="/config/producPackage">
                产品包设置
              </el-menu-item>
              <el-menu-item index="/config/productClass">
                产品分类
              </el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="/asset">
            <template slot="title">
              <img class="img" src="../../static/img/finance.png" alt="" />
              <span>资金管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/asset/withdrawalVerify"
                >达人提现审核</el-menu-item
              >
              <el-menu-item index="/asset/serviceWithdrawal"
                >服务商提现审核</el-menu-item
              >
              <el-menu-item index="/asset/master">达人明细</el-menu-item>
              <el-menu-item index="/asset/provider">服务商明细</el-menu-item>
              <!-- <el-menu-item index="/asset/commission"
                >代理收益明细</el-menu-item
              > -->
            </el-menu-item-group>
          </el-submenu>
          <el-submenu index="/report">
            <template slot="title">
              <i class="el-icon-files"></i>

              <span>报表管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/report/day">日报表</el-menu-item>
              <el-menu-item index="/report/task">任务报表</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
          <!-- <el-submenu index="/scissor">
            <template slot="title">
              <img class="img" src="../../static/img/master.png" alt="" />
              <span>剪刀手</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/scissor/copywriting-list"
                >文案列表</el-menu-item
              >
              <el-menu-item index="/scissor/abstract">摘要列表</el-menu-item>
              <el-menu-item index="/scissor/title-list">标题列表</el-menu-item>
              <el-menu-item index="/scissor/share"> 充值配置 </el-menu-item>
              <el-menu-item index="/scissor/rechargePackage">
                充值套餐
              </el-menu-item>
              <el-menu-item index="/scissor/rechargeRecord"
                >充值记录</el-menu-item
              >
              <el-menu-item index="/scissor/numbeRecords"
                >条数明细</el-menu-item
              >
              <el-menu-item index="/scissor/taskList">任务列表</el-menu-item>
            </el-menu-item-group>
          </el-submenu> -->
          <!-- <el-submenu index="/userCenter">
            <template slot="title">
              <img class="img" src="../../static/img/system.png" alt="" />
              <span>用户中心</span>
            </template> -->
            <!-- <el-menu-item-group> -->
              <!-- <el-menu-item index="/userCenter/userInfo">用户信息</el-menu-item> -->
              <!-- <el-menu-item index="/userCenter/addPiece">添加条数</el-menu-item> -->
              <!-- <el-menu-item index="/userCenter/addPieceRecord"
                >操作记录</el-menu-item
              > -->
              <!-- <el-menu-item index="/userCenter/changeMoney"
                >余额变更</el-menu-item
              >
              <el-menu-item index="/userCenter/record"
                >余额操作记录</el-menu-item
              > -->
              <!-- <el-menu-item index="/userCenter/shareLog">分享记录</el-menu-item> -->
              <!-- <el-menu-item index="/userCenter/userList">用户列表</el-menu-item>
              <el-menu-item index="/userCenter/walletList"
                >钱包记录</el-menu-item
              >

              <el-menu-item index="/userCenter/loginLog">登陆日志</el-menu-item> -->
            <!-- </el-menu-item-group> -->
          <!-- </el-submenu> -->
          <el-submenu index="/system">
            <template slot="title">
              <img class="img" src="../../static/img/system.png" alt="" />
              <span>系统管理</span>
            </template>
            <el-menu-item-group>
              <el-menu-item index="/system/user">用户管理</el-menu-item>
              <!-- <el-menu-item index="/system/role">角色管理</el-menu-item> -->
              <!-- <el-menu-item index="/system/operateLog">操作日志</el-menu-item> -->
              <el-menu-item index="/system/loginLog">登录日志</el-menu-item>
            </el-menu-item-group>
          </el-submenu>
        </el-menu>
      </el-aside>
      <el-container>
        <el-header>
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item v-for="(v, i) in bread" :key="i" :to="v.to">{{
              v.title
            }}</el-breadcrumb-item>
          </el-breadcrumb>

          <div class="right">
            <el-avatar :size="42" :src="user.avatar"></el-avatar>
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                {{ user.name }}
                <i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <!-- <el-dropdown-item command="account">个人中心</el-dropdown-item> -->
                <el-dropdown-item command="logout">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </el-header>
        <el-main
          ><!-- 二级出口 -->
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
// import { api_login_out, api_employee_info } from "@/api/index";

export default {
  data() {
    return {
      collapse: false,
      bread: [],
      user: {
        name: "月球漫步",
        avatar:
          "http://store-tiktok.oss-cn-beijing.aliyuncs.com/mini/images/1680140741992149.png",
      },
      navList: [],
      num: 0,
    };
  },
  computed: {
    defactive() {
      const routerPath = this.$route.path;
      const routerList = routerPath.split("/");
      // console.log(" routerList====================", routerList);
      if (routerList.length == 4) {
        routerList.pop();
        // console.log(" routerList.pop()", routerList);
        return routerList.join("/");
      } else {
        // console.log("routerPath", routerPath);
        return routerPath;
      }
    },
  },
  created() {
    // let timer = setTimeout(() => {
    //   this.num++;
    //   if (this.num == 1) {
    //     // console.log(num);
    //     location.reload();
    //     //关闭定时器
    //     clearTimeout(timer);
    //   }
    // }, 500);
    // 拿到所有的路由
    const routes = this.$router.options.routes;

    // 过滤出导航栏需要的路由
    const nav = routes.filter((v) => {
      if (!["/", "/login", "/*"].includes(v.path)) {
        return true;
      }
      return false;
    });
    this.navList = nav;
    // console.log(nav);
    this.user.name = localStorage.getItem("userName");
    this.callbread();
    this.getUserInfo();
    this.$bus.$on("updateInfo", () => {
      this.getUserInfo();
    });
  },
  watch: {
    $route() {
      this.callbread();
    },
  },
  // computed: {
  //   defactive() {
  //     const routerPath = this.$route.path;
  //     const routerList = routerPath.split("/");
  //     // console.log(routerList);
  //     if (routerList.length == 3) {
  //       routerList.pop();
  //       return routerList.join("/");
  //     } else {
  //       return routerPath;
  //     }
  //   },
  // },
  methods: {
    getUserInfo() {
      // api_employee_info().then((res) => {
      //   localStorage.setItem("id", res.data.data.id);
      //   this.user = res.data.data;
      // });
    },
    handleCollapse() {
      this.collapse = !this.collapse;
    },
    callbread() {
      let r = this.$route.matched.filter((v) => {
        return v && v.meta && v.meta.title;
      });

      let arr = r.map((v) => {
        return { title: v.meta.title, to: v.path };
      });
      this.bread = [...arr];
    },
    handleCommand(type) {
      if (type == "logout") {
        localStorage.clear();
        // 退出登录时强制刷新浏览器
        // location.reload();
        this.$router.push("/login");
        // api_login_out().then((res) => {
        //   console.log(11, res);
        // });
      }
    },
  },
};
</script>

<style lang="less" scoped>
// .el-icon-s-home {
//   font-size: 24px;
//   // padding-right: 20px;
//   position: relative;
//   left: -45px;
// }
.layout .el-container .el-aside .el-menu[data-v-5d5a9732] .el-submenu__title i {
  font-size: 24px;
}

.layout {
  height: 100%;
  background-color: #f3f4ff;

  .el-container {
    overflow: hidden;

    i {
      color: #fff;
    }

    .hide {
      display: none;
    }

    .el-header {
      padding: 0;
      display: flex;
      background-color: #fff;
      margin-bottom: 3px;

      .title {
        display: flex;
        align-items: center;
        margin-right: 20px;

        img {
          width: 44px;
          height: 44px;
        }

        p {
          font-size: 22px;
          color: #333333;
        }
      }

      .el-breadcrumb {
        margin-left: 20px;
        flex: 1;
        display: flex;
        align-items: center;

        i {
          font-size: 20px;
          margin-right: 20px;
        }

        /deep/ .el-breadcrumb__separator {
          color: #ff5f6d;
        }
      }

      .right {
        display: flex;
        align-items: center;
        margin-right: 30px;

        i {
          font-size: 20px;
          color: #666666;
        }

        .el-avatar--circle {
          margin-right: 24px;
        }

        .el-dropdown {
          i {
            margin-left: 10px;
          }
        }
      }
    }

    height: 100%;

    .el-aside {
      height: 100%;
      // background-color: #ff626d !important;
      background: linear-gradient(42deg, #ff5f6d 0%, #fe846c 100%) !important;

      .top {
        display: flex;
        align-items: center;
        padding-left: 30px;
        margin: 25px 0 30px 0;

        .img {
          width: 60px;
          height: 60px;
          background: #ffffff;
          border-radius: 16px;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 20px;

          img {
            padding-left: 5px;
          }
        }

        h2 {
          color: #fff;
          letter-spacing: 3.2px;
        }
      }

      .el-menu {
        border: none;
        border-right: none;
        background: transparent;

        .el-submenu {
          margin-bottom: 10px;

          .img {
            width: 26px;
            height: 26px;
          }
        }

        /deep/ .el-submenu .el-menu {
          background: transparent;
        }

        .el-submenu__title * {
          font-size: 16px;
          margin-left: 14px;
        }

        /deep/ .el-submenu__title {
          // height: 64px;
          letter-spacing: 1.2px;
        }

        /deep/ .el-submenu__title:hover {
          background: rgba(0, 0, 0, 0.2);
        }

        /deep/ .el-submenu__title i {
          color: #fff !important;
        }

        .el-menu-item {
          min-width: none;
          padding-left: 81px !important;
          text-align: left;

          &.home {
            margin-bottom: 10px;
            font-size: 16px;
            // height: 64px;
            padding-left: 34px !important;
            text-align: left;
            letter-spacing: 2px;

            i {
              font-size: 26px;
              margin-right: 26px;
            }

            &:hover {
              color: #fff !important;
            }

            &.is-active {
              color: #ff5f6d !important;
            }
          }
        }

        .el-menu-item:hover {
          background: rgba(0, 0, 0, 0.3) !important;
        }

        .el-menu-item.is-active {
          // width: 234px;
          background: #f3f4ff !important;
          border-radius: 30px 0px 0px 30px;
        }

        .el-menu-item.is-active i {
          color: #ff626d !important;
        }
      }
    }

    .el-main {
      background: #f3f4ff;
      overflow: auto;
      padding: 20px 20px 0 20px;
    }
  }
}
</style>
